import React, {useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import { useDarkMode } from '../../context/darkModeContext'; 
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
//import { isValidDate } from '../../utils/utils';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';
import { addDays, subDays } from 'date-fns';
import es from 'date-fns/locale/es'; // Importa el idioma español

const Actividades = () => {
    // eslint-disable-next-line
    const {darkMode, setDarkMode} = useDarkMode();
    // Obtener la fecha actual
    const fechaActual = new Date();

    // Obtener el día, el mes y el año de la fecha actual
    const dia = fechaActual.getDate().toString().padStart(2, '0');
    const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
    const anio = fechaActual.getFullYear();

    // Crear la cadena con el formato "dd-mm-yyyy"
    const fechaFormateada = `${anio}-${mes}-${dia}`;

    const minSelectableDate = subDays(new Date(), 30);
    const maxSelectableDate = addDays(new Date(), 30);

    const [modalFecha, setModalFecha] = useState(fechaFormateada);
    const [modalIdEspecie, setModalIdEspecie] = useState('');
    const [modalIdTipoActividad, setModalIdTipoActividad] = useState('');
    const [modalModalidad, setModalModalidad] = useState('INDIVIDUAL');

    const [especiesModal, setEspeciesModal] = useState([]);
    const [tiposActividadModal, setTiposActividadModal] = useState([]);

    const modalInputTipoActividad = useRef(null);
    const modalInputEspecie = useRef(null);
    const modalInputModalidad = useRef(null);
    const modalCrearActividad = useRef(null);
    
    const navigate = useNavigate();
    const modulo = 'actividades'
    const bootstrap = window.bootstrap; // se agregó para poder llamar al método hide() de un modal

    const [events, setEvents] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const getEspecies = () => {
        axios.get(`${process.env.REACT_APP_URL_API_PORT}/especies`,{withCredentials: true})
            .then(response => {
            setEspeciesModal(response.data);
        })
        .catch(error => {
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
    };

    const getTiposActividad = () => {
        axios.get(`${process.env.REACT_APP_URL_API_PORT}/tiposactividad`,{withCredentials: true})
            .then(response => {
            setTiposActividadModal(response.data);
        })
        .catch(error => {
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
    };

    const getActividades = () => {
        axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}?start=${startDate.toISOString()}&end=${endDate.toISOString()}`,{withCredentials: true})
            .then(response => {
            setEvents(response.data);
        })
        .catch(error => {
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
    };

    useEffect( ()=> {
        getEspecies();
        getTiposActividad();
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        if (especiesModal.length > 0 && modalInputEspecie.current) {
            setModalIdEspecie(modalInputEspecie.current.selectedOptions[0].value);
        }
    }, [especiesModal]); //Este se ejecuta cuando se carga completo el arreglo  

    useEffect(() => {
        if (tiposActividadModal.length > 0 && modalInputTipoActividad.current) {
            setModalIdTipoActividad(modalInputTipoActividad.current.selectedOptions[0].value);
        }
    }, [tiposActividadModal]); //Este se ejecuta cuando se carga completo el arreglo  

    useEffect(() => {
        if(startDate && endDate && currentView !=='dayGridDay'){
            getActividades();
        }
        //eslint-disable-next-line
    }, [startDate, endDate]);

    function handleDateChange(date, name) {
        if(date){      
            setModalFecha(format(date, 'yyyy-MM-dd'));
        }
    }

    const handleEventClick = (info) => {
        //console.log('Evento clickeado:', info.event.title);
        //extendedProps se usa para props que no son estandar en fullcaneldar
        //console.log('Evento clickeado:', info.event.extendedProps.modulo); 
        if(info.event.extendedProps.modulo !== ''){
            window.open(info.event.extendedProps.modulo + '/' + info.event.id, '_blank');
        } else {
            navigate(`${info.event.id}`);
        }
        
        // Detener la propagación del evento para evitar que se dispare dateClick
        info.jsEvent.stopPropagation();
    };

    const handleDateClick = (arg) => {
        setModalFecha(arg.dateStr);
        const modal = new bootstrap.Modal(modalCrearActividad.current);
        modal.show();
    };

    const handleCustomButtonClick = () => {
        const modal = new bootstrap.Modal(modalCrearActividad.current);
        modal.show();
    }

    const CrearActividad = () => {
        if(!localStorage.getItem("usuario")){
            localStorage.removeItem("token"); navigate('/login'); return;
        }

        Swal.fire({
            title: 'Nueva Actividad', text: '¿Está seguro que desea crear una nueva actividad?',
            icon: 'question', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
            confirmButtonText: 'Crear', cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) 
            {
                var data = {
                    fecha               : modalFecha,
                    id_especie          : modalIdEspecie,
                    id_tipoactividad    : modalIdTipoActividad,
                    modalidad           : modalModalidad, 
                    usuario             : localStorage.getItem("usuario")
                };
        
                axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`,data,{withCredentials: true}).then(response =>
                {
                //***********************************
                //Cerrar modal
                const modalElement = modalCrearActividad.current;
                if (modalElement) {
                    const bootstrapModal = bootstrap.Modal.getInstance(modalElement);
                    bootstrapModal.hide();
                } 
                //***********************************
        
                if (response.data) {
                    toast.success('¡Registro creado correctamente!', {
                    position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
                    pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
                    });
                    navigate(`/${modulo}/${response.data.id_actividad}`);
                } else {
                    toast.error('¡No se pudo crear el registro!', {
                    position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
                    pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
                    });
                }
                })
                .catch(error => {
                    console.log(error);
                    if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
                });
            }
        });
    }

    const handleEventMouseEnter = (info) => {
        // Cambia el cursor al pasar sobre un evento
        info.el.style.cursor = 'pointer';
    };

    const handleDates = (arg) => {
        setStartDate(arg.start);
        setEndDate(arg.end);
        setCurrentView(arg.view.type);
        // console.log(arg.view.type)

        const fcAs = document.querySelectorAll('.fc a');
        fcAs.forEach((a) => {
            a.style.color = colorDia;
        });        
    };

    const [colorDia, setColorDia] = useState('');
    const [colorBorder, setColorBorder] = useState('');
    //const [colorBgEvent, setColorBgEvent] = useState('');

    const [colorBgDiaTimeGrid, setColorBgDiaTimeGrid] = useState('#000');

    const [colorBgListTimeGrid, setColorBgListTimeGrid] = useState('');
    const [colorListTimeGrid, setColorListTimeGrid] = useState('');

    //const [eventColor, setEventColor] = useState('');
    const [eventColorBg, setEventColorBg] = useState('');
    const [eventColorBorder, setEventColorBorder] = useState('');
    const [eventColorText, setEventColorText] = useState('');

    const [currentView, setCurrentView] = useState('dayGridMonth');

    const [colorBgButtonHeader, setColorBgButtonHeader] = useState('');
    const [colorBorderButtonHeader, setColorBorderButtonHeader] = useState('');
    const [colorBgButtonHeaderSelected, setColorBgButtonHeaderSelected] = useState('');

    useEffect( ()=> {
        if(darkMode){
            setColorDia('#ddd');
            setColorBorder('#444');
            // setEventColor('#ddd');
            //setColorBgEvent('#ddd');

            setEventColorBg('#ccc');
            setEventColorBorder('#444');
            setEventColorText('#444');

            setColorBgDiaTimeGrid('#222'); 
            setColorBgListTimeGrid('#bbb');
            setColorListTimeGrid('#444');

            setColorBgButtonHeader('');
            setColorBorderButtonHeader('#141824');
            setColorBgButtonHeaderSelected('');
        } else {
            setColorDia('#444');
            setColorBorder('#666');
            // setEventColor('#444');
            //setColorBgEvent('#444');

            setEventColorBg('#444');
            setEventColorBorder('#999');
            setEventColorText('#ddd');

            setColorBgDiaTimeGrid('#bbb');
            setColorBgListTimeGrid('#ddd');
            setColorListTimeGrid('#444');

            setColorBgButtonHeader('#999');
            setColorBorderButtonHeader('#fff');
            setColorBgButtonHeaderSelected('#666');
        }
    },[darkMode]);

    useEffect( ()=> {
        setColorsCalendarGeneralyEvents(currentView);
        //eslint-disable-next-line
    }, [currentView])

    useEffect( ()=> {
        setColorsCalendarEvents(currentView);
        //eslint-disable-next-line
    }, [events])

    function setColorsCalendarEvents (viewType) {
        const fcAs = document.querySelectorAll('.fc a');
        fcAs.forEach((a) => {
            a.style.color = colorDia;
        });

        const fcDayGridDivs = document.querySelectorAll('.fc-daygrid-day');
        fcDayGridDivs.forEach((div) => {
            div.style.border = `1px solid ${colorBorder}`;
        });

        // const fcHEvents = document.querySelectorAll('.fc-h-event');
        // fcHEvents.forEach((evento) => {
        //     evento.style.backgroundColor = colorBgEvent;
        //     evento.style.border = `1px solid ${eventColorBorder}`;
        // });

        // const fcVEvents = document.querySelectorAll('.fc-v-event');
        // fcVEvents.forEach((evento) => {
        //     evento.style.backgroundColor = colorBgEvent;
        //     evento.style.border = `1px solid ${eventColorBorder}`;
        // });

        const fcListDay = document.querySelectorAll('.fc-list-day > *');
        fcListDay.forEach((evento) => {
            evento.style.backgroundColor = colorBgDiaTimeGrid; //color bg nombre dia listweek
        });

        const fcListDayTd = document.querySelectorAll('.fc-list-event');
        fcListDayTd.forEach((evento) => {
            evento.style.color = colorListTimeGrid; //Color time evento listweek
            evento.style.backgroundColor = colorBgListTimeGrid; //color Bg tupla completo del evento
        });

        const fcListDayTda = document.querySelectorAll('.fc-list-event a');
        fcListDayTda.forEach((evento) => {
            evento.style.color = colorListTimeGrid; //Color a href title evento listweek
        });
    };

    function setColorsCalendarGeneralyEvents (viewType) {
        // console.log(viewType)
        // const gridTable = document.querySelector('[role="grid"]');
        // gridTable.style.display = '';

        const fcAs = document.querySelectorAll('.fc a');
        fcAs.forEach((a) => {
            a.style.color = colorDia;
        });

        const fcDayGridDivs = document.querySelectorAll('.fc-daygrid-day');
        fcDayGridDivs.forEach((div) => {
            div.style.border = `1px solid ${colorBorder}`;
        });

        const fcTollbarTitle = document.querySelector('.fc-toolbar-title');
        fcTollbarTitle.style.fontSize = '1.2em';

        const fcHeaderDivs = document.querySelectorAll('.fc-col-header-cell');
        fcHeaderDivs.forEach((div) => {
            div.style.border = `1px solid ${colorBorder}`;
        });

        if(viewType !== 'listWeek'){
            const scrollGrid = document.querySelector('.fc-scrollgrid');
            scrollGrid.style.border = `1px solid ${colorBorder}`;
        }

        if(viewType === 'timeGridWeek'){
            const fcTimeGridDivider = document.querySelector('.fc-timegrid-divider');
            fcTimeGridDivider.style.border = `1px solid ${colorBorder}`;

            const fcTimeGridAxis = document.querySelectorAll('.fc-timegrid-axis');
            fcTimeGridAxis.forEach((div) => {
                div.style.border = `1px solid ${colorBorder}`;
            }); 

            const fcTimeGridSlot = document.querySelectorAll('.fc-timegrid-slot');
            fcTimeGridSlot.forEach((div) => {
                div.style.border = `1px solid ${colorBorder}`;
            }); 
        }

        //Se repasan los eventos (No sacar)
        // const fcHEvents = document.querySelectorAll('.fc-h-event');
        // fcHEvents.forEach((evento) => {
        //     evento.style.backgroundColor = colorBgEvent;
        //     evento.style.border = `1px solid ${colorBgEvent}`;
        // });

        // const fcVEvents = document.querySelectorAll('.fc-v-event');
        // fcVEvents.forEach((evento) => {
        //     evento.style.backgroundColor = colorBgEvent;
        //     evento.style.border = `1px solid ${colorBgEvent}`;
        // });

        if(viewType === 'listWeek'){
            // const fcListDay = document.querySelector('.fc-day');
            // fcListDay.style.backgroundColor = '#CCC';

            // const fcListSticky = document.querySelectorAll('.fc-list-sticky');
            // fcListSticky.forEach((evento) => {
            //     evento.style.backgroundColor = '#111';
            // });

            const fcListDay = document.querySelectorAll('.fc-list-day > *');
            fcListDay.forEach((evento) => {
                evento.style.backgroundColor = colorBgDiaTimeGrid; //color bg nombre dia listweek
            });

            const fcListDayTd = document.querySelectorAll('.fc-list-event');
            fcListDayTd.forEach((evento) => {
                evento.style.color = colorListTimeGrid; //Color time evento listweek
                evento.style.backgroundColor = colorBgListTimeGrid; //color Bg tupla completo del evento
            });

            const fcListDayTda = document.querySelectorAll('.fc-list-event a');
            fcListDayTda.forEach((evento) => {
                evento.style.color = colorListTimeGrid; //Color a href title evento listweek
            });

            const fcPrueba = document.querySelector('.fc-listWeek-view');
            fcPrueba.style.border = `1px solid #444`;   
        }

        //Botones Header
        
        const fcPrevButton = document.querySelector('.fc-prev-button');
        fcPrevButton.style.backgroundColor = colorBgButtonHeader;
        fcPrevButton.style.border = `1px solid ${colorBorderButtonHeader}`;

        const fcNextButton = document.querySelector('.fc-next-button');
        fcNextButton.style.backgroundColor = colorBgButtonHeader;
        fcNextButton.style.border = `1px solid ${colorBorderButtonHeader}`;

        const fcTodayButton = document.querySelector('.fc-today-button');
        fcTodayButton.style.backgroundColor = colorBgButtonHeader;
        fcTodayButton.style.border = `1px solid ${colorBorderButtonHeader}`;

        const fcCustomButton = document.querySelector('.fc-customButton-button');
        fcCustomButton.style.backgroundColor = colorBgButtonHeader;
        fcCustomButton.style.border = `1px solid ${colorBorderButtonHeader}`;

        
        const fcDayGridMonthButton = document.querySelector('.fc-dayGridMonth-button');
        fcDayGridMonthButton.style.border = `1px solid ${colorBorderButtonHeader}`;
        if(viewType === 'dayGridMonth'){
            fcDayGridMonthButton.style.backgroundColor = colorBgButtonHeaderSelected;
        } else {
            fcDayGridMonthButton.style.backgroundColor = colorBgButtonHeader;
        }
        
        const fcDayGridWeekButton = document.querySelector('.fc-dayGridWeek-button');
        fcDayGridWeekButton.style.border = `1px solid ${colorBorderButtonHeader}`;
        if(viewType === 'dayGridWeek'){
            fcDayGridWeekButton.style.backgroundColor = colorBgButtonHeaderSelected;
        } else {
            fcDayGridWeekButton.style.backgroundColor = colorBgButtonHeader;
        }
        
        const fcDayGridDayButton = document.querySelector('.fc-dayGridDay-button');
        fcDayGridDayButton.style.border = `1px solid ${colorBorderButtonHeader}`;
        if(viewType === 'dayGridDay'){
            fcDayGridDayButton.style.backgroundColor = colorBgButtonHeaderSelected;
        } else {
            fcDayGridDayButton.style.backgroundColor = colorBgButtonHeader;
        }
        
        const fcTimeGridWeekButton = document.querySelector('.fc-timeGridWeek-button');
        fcTimeGridWeekButton.style.border = `1px solid ${colorBorderButtonHeader}`;
        if(viewType === 'timeGridWeek'){
            fcTimeGridWeekButton.style.backgroundColor = colorBgButtonHeaderSelected;
        } else {
            fcTimeGridWeekButton.style.backgroundColor = colorBgButtonHeader;
        }
        
        const fcListWeekButton = document.querySelector('.fc-listWeek-button');
        fcListWeekButton.style.border = `1px solid ${colorBorderButtonHeader}`;
        if(viewType === 'listWeek'){
            fcListWeekButton.style.backgroundColor = colorBgButtonHeaderSelected;
        } else {
            fcListWeekButton.style.backgroundColor = colorBgButtonHeader;
        }
    };

    useEffect( ()=> {
        setColorsCalendarGeneralyEvents(currentView);
        //eslint-disable-next-line
    },[colorDia]);

    const calendarRef = useRef(null);

    return (
        <div className="row justify-content-center w-100">
            <div className="col">
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                    headerToolbar={{
                        left: 'prev,next today customButton',
                        center: 'title',
                        right: 'dayGridMonth,dayGridWeek,dayGridDay,timeGridWeek,listWeek',
                    }}
                    customButtons= {{
                        customButton:{
                            text: '(+) Agregar',
                            click: handleCustomButtonClick,
                        }
                    }}
                    buttonText={{
                        today: 'Hoy',
                        month: 'Mes',
                        week: 'Semana',
                        day: 'Día', 
                        timeGridWeek: 'Horario sem', 
                        listWeek: 'Lista sem'
                    }}
                    eventClick={handleEventClick} 
                    dateClick={handleDateClick} // Asocia la función handleDateClick con el evento de clic en la fecha
                    firstDay={1} // Establece el primer día de la semana como lunes
                    locale={'es'} // Establece la localización a español
                    fixedWeekCount={false} // Permite que el calendario tenga un número variable de semanas
                    datesSet={handleDates}
                    eventMouseEnter={handleEventMouseEnter}
                    // eventColor={eventColor} //Esto funciona sólo para eventos allDay
                    eventBorderColor={eventColorBorder} //Esto funciona sólo para eventos allDay
                    eventTextColor={eventColorText} //Esto funciona sólo para eventos allDay
                    eventBackgroundColor={eventColorBg} //Esto funciona sólo para eventos allDay
                    // dayCellContent={customContent}
                    // dayCellDidMount={handleDayCellDidMount}                    
                />
                
                <div className="modal fade" id="modalCrearActividad" ref={modalCrearActividad} 
                    tabIndex="-1" aria-labelledby="modalCrearActividadLabel" aria-hidden="true">
                    <div className="modal-dialog border border-secondary">
                        <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
                            <div className="modal-header border-bottom border-secondary">
                                <h5 className="modal-title" id="modalCrearActividadLabel">Agregar Actividad</h5>
                                <button 
                                    type="button" 
                                    className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                                    data-bs-dismiss="modal" aria-label="Close">
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row pb-2">
                                    <label className="col-sm-4">Fecha:</label>
                                    <div className="col-sm-8">
                                    <DatePicker
                                        selected={parseISO(modalFecha, 'ddMMyyyy', new Date())}
                                        onChange={(date) => handleDateChange(date)}
                                        minDate={minSelectableDate}
                                        maxDate={maxSelectableDate}
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control form-control-sm" 
                                        required 
                                        locale={es} // Establece el idioma español
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select" // Cambia a 'scroll' si prefieres un scroll en lugar de select
                                        />
                                    </div>
                                </div>

                                <div className="row pb-2">
                                    <label className="col-sm-4">Tipo:</label>
                                    <div className="col-sm-8">
                                    <select value={modalIdTipoActividad} 
                                        onChange={(event) => {setModalIdTipoActividad(event.target.value)}}
                                        className="form-select form-select-sm" name="modal_id_tipoactividad" ref={modalInputTipoActividad}>
                                        {tiposActividadModal.map((opcion) => (
                                        <option key={opcion.id_tipoactividad} value={opcion.id_tipoactividad}>{opcion.n_tipoactividad}</option>
                                        ))}
                                    </select>
                                    </div>
                                </div>
                                {(modalIdTipoActividad !== '1' && modalIdTipoActividad !== '2' && modalIdTipoActividad !== '3') && 
                                <div className="row pb-2">
                                    <label className="col-sm-4">Modalidad Animal:</label>
                                    <div className="col-sm-8">
                                    <select value={modalModalidad} 
                                        onChange={(event) => {setModalModalidad(event.target.value)}}
                                        className="form-select form-select-sm" name="modal_modalidad" ref={modalInputModalidad}>
                                        <option key={1} value={'INDIVIDUAL'}>Individual</option>
                                        {modalIdTipoActividad !=='7' && 
                                        <option key={2} value={'LOTE'}>Lote</option>
                                        }
                                    </select>
                                    </div>
                                </div>
                                }

                                {(modalIdTipoActividad !== '1' && modalIdTipoActividad !== '2' && modalIdTipoActividad !== '3') && 
                                <div className="row pb-2">
                                    <label className="col-sm-4">Especie:</label>
                                    <div className="col-sm-8">
                                    <select value={modalIdEspecie} 
                                        onChange={(event) => {setModalIdEspecie(event.target.value)}}
                                        className="form-select form-select-sm" name="modal_id_especie" ref={modalInputEspecie}>
                                        {especiesModal.map((opcion) => (
                                        <option key={opcion.id_especie} value={opcion.id_especie}>{opcion.n_especie}</option>
                                        ))}
                                    </select>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="modal-footer border-top border-secondary">
                            <button type="button" className="btn btn-success"
                                onClick={() => CrearActividad()}>
                                Crear
                            </button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                Cancelar
                            </button>
                            </div>
                        </div>
                    </div>
                </div>{/*fin modal*/}
            </div>
        </div>

        
    );
};

export default Actividades;