import React, {  useRef, useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDarkMode } from '../../context/darkModeContext'; 

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';
import es from 'date-fns/locale/es'; // Importa el idioma español

import { CalcularEdad } from '../../utils/utils';

function LotesForm() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const inputEspecie        = useRef(null);
  const inputRaza           = useRef(null);
  const inputNacpredio      = useRef(null);
  const inputNacpotrero     = useRef(null);
  const inputNacsubpotrero  = useRef(null);
  const inputSexo           = useRef(null);
  const inputCapado         = useRef(null);
  const inputCantidad       = useRef(null);

  const { id_lote }                 = useParams();
  const [icon, setIcon]             = useState('');
  const [textButton, setTextButton] = useState('');

  const [isLoadingDatosForm, setIsLoadingDatosForm]     = useState(true);
  const [isLoadingMovimientos, setIsLoadingMovimientos] = useState(true);

  const modulo = 'lotes'

  const navigate = useNavigate();
  
  const fechaActual = new Date(); // Obtener la fecha actual
  // Obtener el día, el mes y el año de la fecha actual
  const dia   = fechaActual.getDate().toString().padStart(2, '0');
  const mes   = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
  const anio  = fechaActual.getFullYear();
  const fechaFormateada = `${anio}-${mes}-${dia}`; // Crear la cadena con el formato "dd-mm-yyyy"

  const initialDatoState = {
    id_lote               : null,
    created_fecha         : '',
    id_especie            : '', 
    id_raza               : '',
    sexo                  : 'M',
    capado                : false,
    nac_fecha             : fechaFormateada,
    nac_id_predio         : '',
    nac_id_potrero        : '',
    nac_id_subpotrero     : '',
    cantidad              : '1',
    actual_id_predio      : '',
    actual_id_potrero     : '',
    actual_id_subpotrero  : '',
  };

  const [actualIdPredio, setActualIdPredio]         = useState('');
  const [actualNPredio, setActualNPredio]           = useState('');
  const [actualIdPotrero, setActualIdPotrero]       = useState('');
  const [actualNPotrero, setActualNPotrero]         = useState('');
  const [actualIdSubpotrero, setActualIdSubpotrero] = useState('');
  const [actualNSubpotrero, setActualNSubpotrero]   = useState('');

  const [dato, setDato]               = useState(initialDatoState);
  const [especies, setEspecies]       = useState([]);
  const [razas, setRazas]             = useState([]);
  const [edad, setEdad]               = useState('');
  const [categoria, setCategoria]     = useState('');
  const [predios, setPredios]         = useState([]);
  const [potreros, setPotreros]       = useState([]);
  const [subpotreros, setSubpotreros] = useState([]);
  const [movimientos, setMovimientos] = useState([]);

  useEffect(() => {
    if (id_lote) {
      getDato(id_lote);
      setIcon('fas fa-arrow-left');
      setTextButton('Volver');
      getMovimientos();
    }
    else {
      setIcon('fas fa-ban');
      setTextButton('Cancelar');
      setIsLoadingDatosForm(false);
    }
    getEspecies();
    getPredios();
    //eslint-disable-next-line
  }, [id_lote]);

  const getDato = async (id_lote) => {
    setIsLoadingDatosForm(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_lote}`,{withCredentials: true});
      setDato(response.data);
      setIsLoadingDatosForm(false);
    } catch(error) {console.log(error);}
  };

  //=================== ESPECIES ===================
  //=====> 1° Api
  const getEspecies = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/especies`,{withCredentials: true});
      setEspecies(response.data);
    } catch(error) {console.log(error);}
  };
  //=====> 2° Cuando termina de cargar el array
  useEffect(() => {
    if (especies.length > 0 && inputEspecie.current) {
      setDato(prevDato => ({...prevDato, 'id_especie': inputEspecie.current.selectedOptions[0].value}));
    }
  }, [especies]);

  //=================== RAZAS ===================
  //=====> 1° Api
  const getRazas = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/razasxespecie/${dato.id_especie}`,{withCredentials: true});
      setRazas(response.data);
    } catch(error){console.log(error);};
  };
  //=====> 2° Cuando termina de cargar el array
  useEffect(() => {
    if (razas.length > 0 && inputRaza.current) {
      setDato(prevDato => ({...prevDato, 'id_raza': inputRaza.current.selectedOptions[0].value}));
    }
  }, [razas]);
  //=====> 3° Cargar razas cuando cambia id_especie
  useEffect(() => {
    if (dato.id_especie !== '' ) {getRazas();}
    //eslint-disable-next-line
  }, [dato.id_especie]);

  //=================== PREDIOS ===================
  //=====> 1° Api
  const getPredios = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/predios`,{withCredentials: true})
        setPredios(response.data);
    } catch(error) {console.log(error);}
  };
  //=====> 2° Cuando termina de cargar el array
  useEffect(() => {
    if (predios.length > 0 && inputNacpredio.current) {
      setDato(prevDato => ({...prevDato, 'nac_id_predio': inputNacpredio.current.selectedOptions[0].value}));
    }
  }, [predios]);

  //=================== POTREROS ===================
  //=====> 1° Api
  const getPotreros = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/potrerosxpredio/${dato.nac_id_predio}`,{withCredentials: true});
      setPotreros(response.data);
    } catch(error){console.log(error);};
  };
  //=====> 2° Cuando termina de cargar el array
  useEffect(() => {
    if (potreros.length > 0 && inputNacpotrero.current) {
      setDato(prevDato => ({...prevDato, 'nac_id_potrero': inputNacpotrero.current.selectedOptions[0].value}));
    }    
  }, [potreros]);
  //=====> 3° Cargar potreros cuando cambia id_predio
  useEffect(() => {
    if (dato.nac_id_predio !== '') {
      getPotreros();

      if(movimientos.length === 0 && inputNacpredio.current.selectedOptions[0] !== undefined){
        setActualIdPredio(inputNacpredio.current.selectedOptions[0].value);
        setActualNPredio(inputNacpredio.current.selectedOptions[0].text);
      }
    }
    //eslint-disable-next-line
  }, [dato.nac_id_predio]);

  //=================== SUBPOTREROS ===================
  //=====> 1° Api
  const getSubpotreros = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/subpotrerosxpotrero/${dato.nac_id_potrero}`,{withCredentials: true});
      setSubpotreros(response.data);  
    } catch(error){console.log(error);};
  };
  //=====> 2° Cuando termina de cargar el array
  useEffect(() => {
    if (subpotreros.length > 0 && inputNacsubpotrero.current) {
      setDato(prevDato => ({...prevDato, 'nac_id_subpotrero': inputNacsubpotrero.current.selectedOptions[0].value}));   
    }    
  }, [subpotreros]);
  //=====> 3° Cargar subpotreros cuando cambia id_potrero
  useEffect(() => {
    if (dato.nac_id_potrero !== '') {
      getSubpotreros();

      if(movimientos.length === 0 && inputNacpotrero.current.selectedOptions[0] !== undefined){
        setActualIdPotrero(inputNacpotrero.current.selectedOptions[0].value);
        setActualNPotrero(inputNacpotrero.current.selectedOptions[0].text);
      }
    }
    //eslint-disable-next-line
  }, [dato.nac_id_potrero]);
  //=====> 4° Set Subpotrero actual
  useEffect(() => {
    if(movimientos.length === 0 && inputNacsubpotrero.current){
      setActualIdSubpotrero(inputNacsubpotrero.current.selectedOptions[0].value);
      setActualNSubpotrero(inputNacsubpotrero.current.selectedOptions[0].text);
    }
    //eslint-disable-next-line
  }, [dato.nac_id_subpotrero]);
  
  //=================== CATEGORIAS ===================
  const getCategoriaEspecie = async (v_id_especie, v_sexo, v_nac_fecha, v_capado) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_getcategoriaespecie/${v_id_especie}/${v_sexo}/${v_nac_fecha}/${v_capado}`,{withCredentials: true});
      setCategoria(response.data.nombre);
    } catch (error) {console.log(error);}
  };

  const SetCategoriaEspecie = () => {
    let v_capado        = false;
    if(inputCapado.current){
      if(inputSexo.current.value === "H"){inputCapado.current.checked = false}
      v_capado = inputCapado.current.checked;
    }    
    getCategoriaEspecie(dato.id_especie, inputSexo.current.value, dato.nac_fecha, v_capado);
  };

  useEffect(() => {
    if(dato.id_especie !=='' && dato.nac_fecha !== '' && dato.sexo !== '' && dato.capado !== ''){
      SetCategoriaEspecie();
    }
    // eslint-disable-next-line
  }, [dato.id_especie, dato.nac_fecha, dato.sexo, dato.capado]);

  //====================== CALCULAR EDAD ======================
  useEffect(() => {
    if(dato.nac_fecha ){
      setEdad(CalcularEdad(dato.nac_fecha));
    }
  }, [dato.nac_fecha]);

  //---------------------------------------------------------------------
  const getMovimientos = async () => {
    setIsLoadingMovimientos(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/movlote_xidlote/${id_lote}`,{withCredentials: true});
      setMovimientos(response.data);
      setIsLoadingMovimientos(false);
      if(response.data.length > 0){
        getUbicacionActual()
      }
    } catch(error){console.log(error);};
  };

  const getUbicacionActual = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_ubicacionactual/${id_lote}`,{withCredentials: true})
      setActualIdPredio(response.data.actual_id_predio);
      setActualNPredio(response.data.n_predio);
      setActualIdPotrero(response.data.actual_id_potrero);
      setActualNPotrero(response.data.n_potrero);
      setActualIdSubpotrero(response.data.actual_id_subpotrero);
      setActualNSubpotrero(response.data.n_subpotrero);
    } catch(error) {console.log(error);};
  };

  //---------- Funciones Handle ----------------
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };
  
  const handleCheckboxChange = (event) => {
    setDato({ ...dato, [ event.target.name]: event.target.checked });
  };

  function handleDateChange(date, name) {
    if(date){      
      setDato({ ...dato, [name]: format(date, 'yyyy-MM-dd') });
    }
  }

  const handleKeyPressCantidad = (event) => {
    if (event.key === 'Enter') {
      event.target.blur(); // desenfocar el input si se presiona Enter
    }

    if (!/[0-9\b]|Arrow(Left|Right)|Delete|Backspace/.test(event.key)) {
      event.preventDefault();
    }
  };

  const enviarFormulario = async (e) => {
    e.preventDefault();

    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }

    var data = {
      id_lote               : dato.id_lote,
      id_especie            : inputEspecie.current.value,
      id_raza               : inputRaza.current.value,
      sexo                  : dato.sexo,
      capado                : dato.capado,
      nac_fecha             : dato.nac_fecha,
      nac_id_predio         : inputNacpredio.current.value,
      nac_id_potrero        : inputNacpotrero.current.value,
      nac_id_subpotrero     : inputNacsubpotrero.current.value,
      cantidad              : dato.cantidad,
      actual_id_predio      : actualIdPredio,
      actual_id_potrero     : actualIdPotrero,
      actual_id_subpotrero  : actualIdSubpotrero,
      usuario               : localStorage.getItem("usuario")
    };

    try {
      if (dato.id_lote) {
        await axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_lote}`, data,{withCredentials: true});
        toast.success('¡Registro actualizado correctamente!', {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      } else {
        const response = await axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, data,{withCredentials: true});
        toast.success("Registro creado correctamente", {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
  
        navigate(`/${modulo}/${response.data.id_lote}`);
      }
    } catch (error) {
      toast.error(error.response.data, {
        position: "top-right", autoClose: 2000, hideProgressBar: true,
        closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
      });
    }
  };

  const Imprimir = () => {
    // Mostrar el mensaje de carga
    const loadingSwal = Swal.fire({
      title: 'Generando PDF',
      text: 'Por favor, espere un momento...',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      }
    });

    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_pdfLote/${id_lote}`,{withCredentials: true})
      .then(response => {
        window.open(`${process.env.REACT_APP_URL_API_PORT}/pdfs/lote_${id_lote}.pdf`, "_blank");
         // console.log(response);
        loadingSwal.close();
      })
      .catch(error => {
        //console.log(error);
        loadingSwal.close();
        toast.error("Ocurrió un error al generar el pdf", {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      });
  };

  return (
    <div className="row justify-content-center w-100">
      <div className="col col-lg-10 col-xl-8">
      {isLoadingDatosForm &&  
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
        </div>
      }
      {!isLoadingDatosForm &&    
        <form onSubmit={enviarFormulario}>
          <small>
          <div className="row pt-2 pb-2">
            <div className="col text-end">
              {id_lote && (
              <button type="button" className="btn btn-info btn-sm" 
                onClick={() => Imprimir()}>
                <i className="fas fa-print"></i> Imprimir
              </button>
              )}
              &nbsp;
              <button type="submit" className="btn btn-success btn-sm">
                <i className="fas fa-check"></i> Guardar
              </button>
              &nbsp;
              <Link to={`/${modulo}`} className="btn btn-secondary btn-sm">
                <i className={icon} title="Cancelar"></i> {textButton}
              </Link>
            </div>
          </div>

          <fieldset className={`border p-2 mb-2 ${darkMode ? 'border-secondary' : ''}`}>
            {id_lote &&
            <div className="row">
              <label className="col-sm-2">Id. Lote:</label>
              <div className="col-sm-4">
                <input type="text" name="id_lote" value={dato.id_lote} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`}
                readOnly/>
              </div>
              <label className="col-sm-2">Fecha:</label>
              <div className="col-sm-4">
                <input type="text" name="created_fecha" value={dato.created_fecha} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`}
                readOnly/>
              </div>
            </div>
            }

            <div className="row pt-2">
              <label className="col-sm-2">Especie:</label>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="id_especie" ref={inputEspecie} 
                  value={dato.id_especie} onChange={handleInputChange} required>
                  {especies.map((opcion) => (
                    <option key={opcion.id_especie} value={opcion.id_especie}>{opcion.n_especie}</option>
                  ))}
                </select>
              </div>
              <label className="col-sm-2">Raza:</label>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="id_raza" ref={inputRaza} 
                  value={dato.id_raza} onChange={handleInputChange} required>
                  {razas.map((opcion) => (
                    <option key={opcion.id_raza} value={opcion.id_raza}>{opcion.n_raza}</option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="row pt-2">
              <label className="col-sm-2">Sexo:</label>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="sexo" ref={inputSexo} 
                  value={dato.sexo} onChange={(event) => { handleInputChange(event); }} required>
                  <option key={0} value={'M'}>Macho</option>
                  <option key={1} value={'H'}>Hembra</option>
                </select>
              </div>
              <div className="col-sm-6">
                {dato.sexo === "M" &&  
                <div className="form-check">
                  <input className="form-check-input" onChange={handleCheckboxChange} name="capado" ref={inputCapado} 
                    type="checkbox" id="checkCapado" checked={dato.capado}/>
                  <label className="form-check-label" htmlFor="checkCapado">
                    Capado
                  </label>
                </div>
                }
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Fec. Nac.:</label>
              <div className="col-sm-4">
                <DatePicker
                  selected={parseISO(dato.nac_fecha, 'ddMMyyyy', new Date())}
                  onChange={(date) => handleDateChange(date, "nac_fecha")}
                  dateFormat="dd-MM-yyyy"
                  className="form-control form-control-sm" 
                  required 
                  locale={es} // Establece el idioma español
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select" // Cambia a 'scroll' si prefieres un scroll en lugar de select
                />
              </div>
              <label className="col-sm-2">Categoría:</label>
              <div className="col-sm-4">
                <input type="text" name="categoria" value={categoria}
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Edad:</label>
              <div className="col-sm-4">
                <input type="text" name="edad" value={edad}
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Cantidad:</label>
              <div className="col-sm-4">
                <input maxLength="5" name="cantidad" type="text" 
                  className="form-control form-control-sm" 
                  value={dato.cantidad} 
                  onKeyDown={handleKeyPressCantidad} 
                  onChange={handleInputChange}
                  ref={inputCantidad} 
                  required/>
              </div>
            </div>
          </fieldset>
          
          <fieldset className={`border p-2 mb-2 position-relative ${darkMode ? 'border-secondary' : ''}`}>
            <legend 
              className={`w-auto position-absolute ${darkMode ? 'bg-dark' : 'bg-light'}`}
              >Lugar nacimiento</legend>
            <div className="row">
              <label className="col-sm-2">Predio:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="nac_id_predio" 
                  ref={inputNacpredio} 
                  value={dato.nac_id_predio} 
                  onChange={handleInputChange} 
                  disabled={movimientos.length > 0}
                  required>
                  {predios.map((opcion) => (
                    <option key={opcion.id_predio} value={opcion.id_predio}>{opcion.n_predio}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Potrero:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="nac_id_potrero" 
                  ref={inputNacpotrero} 
                  value={dato.nac_id_potrero} 
                  onChange={handleInputChange} 
                  disabled={movimientos.length > 0}
                  required>
                  {potreros.map((opcion) => (
                    <option key={opcion.id_potrero} value={opcion.id_potrero}>{opcion.n_potrero}</option>
                  ))}
                </select>
              </div>
              <label className="col-sm-2">Subpotrero:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="nac_id_subpotrero" 
                  ref={inputNacsubpotrero} 
                  value={dato.nac_id_subpotrero} 
                  onChange={handleInputChange}
                  disabled={movimientos.length > 0}>
                    <option key={0} value={''}></option>
                  {subpotreros.map((opcion) => (
                    <option key={opcion.id_subpotrero} value={opcion.id_subpotrero}>{opcion.n_subpotrero}</option>
                  ))}
                </select>
              </div>
            </div>
          </fieldset>

          <fieldset className={`border p-2 mb-2 position-relative ${darkMode ? 'border-secondary' : ''}`}>
            <legend 
              className={`w-auto position-absolute ${darkMode ? 'bg-dark' : 'bg-light'}`} 
              >Ubicación actual</legend>
            <div className="row">
              <label className="col-sm-2">Predio:</label>
              <div className="col-sm-4">
                <input type="hidden" name="actual_id_predio" value={actualIdPredio} />
                <input type="text" name="actual_n_predio" value={actualNPredio} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Potrero:</label>
              <div className="col-sm-4">
                <input type="hidden" name="actual_id_potrero" value={actualIdPotrero} />
                <input type="text" name="actual_n_potrero" value={actualNPotrero}
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
              <label className="col-sm-2">Subpotrero:</label>
              <div className="col-sm-4">
                <input type="hidden" name="actual_id_subpotrero" value={actualIdSubpotrero} />
                <input type="text" name="actual_n_subpotrero" value={actualNSubpotrero}
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
            </div>
          </fieldset>
          {id_lote && 
          <fieldset className={`border p-2 mb-2 ${darkMode ? 'border-secondary' : ''}`}>
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button className="nav-link active" id="nav-movimientos-tab" data-bs-toggle="tab" data-bs-target="#nav-movimientos" type="button" role="tab" aria-controls="nav-movimientos" aria-selected="false">Movimientos</button>
                {/* <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button> */}
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-movimientos" role="tabpanel" aria-labelledby="nav-movimientos-tab">
                {isLoadingMovimientos &&  
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando datos...</span>
                  </div>
                </div>
                }
                {!isLoadingMovimientos &&  
                <div className="row">
                  <div className="col">
                    <small><label><b>Historial de Movimientos</b></label></small>
                    <table className={`table table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                      <thead>
                        <tr>
                          <th>N° Mov.</th>
                          <th>Fecha</th>
                          <th>Origen</th>
                          <th>Destino</th>
                        </tr>
                      </thead>
                      <tbody>
                        {movimientos.map(tupla => {
                          let strOrigen   = tupla.origen_n_predio + "/" + tupla.origen_n_potrero;
                          if(tupla.origen_n_subpotrero !== ''){strOrigen+="/" + tupla.origen_n_subpotrero}
                          let strDestino  = tupla.destino_n_predio + "/" + tupla.destino_n_potrero;
                          if(tupla.destino_n_subpotrero !== ''){strDestino+="/" + tupla.destino_n_subpotrero}

                          return(
                          <tr key={tupla.id_mov}>
                            <td>{tupla.id_mov}</td>
                            <td>{tupla.fecha_mov_dd_mm_yyyy}</td>
                            <td>{strOrigen}</td>
                            <td>{strDestino}</td>
                          </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                }
              </div>
              {/* <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...C</div> */}
            </div>
          </fieldset>
          }

          </small>
          
        </form>
        }
      </div>

    </div>
  );
}

export default LotesForm;